import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {makeGetInputStateByField} from '../../../redux/selectors/selectorIndex';
import {parseValue} from '../../../helpers/helperIndex';
import {
    ResultWrapper,
    ResultContent
} from '../atomicStyledComponents/stylesIndex';

class ResultDisplay extends PureComponent {
    constructor({
                    data, id, parent,
                    parentID
                }) {
        const {title, field, type} = data;
        super();
        this.state = {loaded: false};

        this.id = id;
        this.parent = parent;
        this.parentID = parentID;
        this.type = type;
        this.title = title;
        this.field = field;
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.loaded) {
            return {loaded: nextProps.loaded}
        }
        ;
        return null;
    }

    render() {
        return (<>
            {this.type !== 'info' &&
            <ResultWrapper className={'results'}>
                {this.props.input
                && this.props.input.length > 0
                && this.props.input.map((item, i) => {
                    if (this.field.includes('DoB')) {
                        return (
                            <ResultContent
                                className={'results'} key={item.value.toString()}>
                                {item.value
                                    ? typeof item.value === 'object'
                                        ? item.value.toString().split(' ').slice(1, 4).join('/')
                                        : new Date(item.value).toString().split(' ').slice(1, 4).join('/')
                                    : 'not answered'}
                                {i < (this.props.input.length - 1) && ','}
                            </ResultContent>)
                    } else if (item.type === "checkbox" && Array.isArray(item.value)){
                        let multiple="";
                        item.value.forEach(i=> {
                            if (i.includes(".")){
                                [i] = i.split('.').slice(-1)
                            }
                            multiple += i
                            if (item.value.length>1){
                                multiple += ", "
                            }
                        })
                        return (<ResultContent className={'results'} key={item.value.length ? item.value[0].toString(): ""}>{multiple}</ResultContent>)
                    }
                    return (
                        <ResultContent
                            className={'results'} key={item.value}>
                            {item.value ? parseValue(item.value) : 'not answered'}{i < (this.props.input.length - 1) && ','}
                        </ResultContent>)
                }) }
                {(!this.props.input || (this.props.input && this.props.input.length < 1))
                &&
                <ResultContent> not answered </ResultContent>}
            </ResultWrapper>}</>)
    }
}

const makeMapStateToProps = _ => {
    const getInputValueFromState = makeGetInputStateByField();

    const mapStateToProps = (state, props) => {
        const input = getInputValueFromState(state, props);

        const field = props.data.field;
        if (field && field.includes('PatientData')) {
            let checkboxVal = (state.formReducer.inputState[`${field}.Checkbox`]
                && state.formReducer.inputState[`${field}.Checkbox`].length > 0)
                ? state.formReducer.inputState[`${field}.Checkbox`][0].value : false;

            if (checkboxVal) {
                let dataState = state.formReducer.inputState
                switch (field) {
                    case 'Safety.PatientData.Height':
                        if (checkboxVal === 'centimetres'
                            && dataState[`${field}.Input.Centimetres`]
                            && dataState[`${field}.Input.Centimetres`].length > 0) {
                            return {
                                input: [{
                                    value: `${dataState[`${field}.Input.Centimetres`][0].value} centimetres`
                                }]
                            }
                        }
                        ;
                        if (checkboxVal === 'feet'
                            && dataState[`${field}.Input.Feet`]
                            && dataState[`${field}.Input.Inches`]
                            && dataState[`${field}.Input.Feet`].length > 0
                            && dataState[`${field}.Input.Inches`].length > 0) {
                            return {
                                input: [{
                                    value: (`${dataState[`${field}.Input.Feet`][0].value} feet, 
                         ${dataState[`${field}.Input.Inches`][0].value} inches`)
                                }]
                            }
                        }
                        ;
                        return 'not answered';
                    case 'Safety.PatientData.Weight':
                        if (dataState[`${field}.Input`] && dataState[`${field}.Input`].length > 0) {
                            if (checkboxVal === 'kilograms') {
                                return {
                                    input: [{
                                        value: `${dataState[`${field}.Input`][0].value} kilograms`
                                    }]
                                }
                            }
                            ;
                            if (checkboxVal === 'pounds') {
                                return {
                                    input: [{
                                        value: `${dataState[`${field}.Input`][0].value} pounds`
                                    }]
                                }
                            }
                        }
                        ;
                        return 'not answered';
                    default:
                        break
                }
            }
        }
        ;
        if (input) {
            return {input}
        }
        ;
        return props
    };
    return mapStateToProps;
}

export default connect(makeMapStateToProps)(ResultDisplay);